var script = document.createElement('script'); // script.src =
//   'https://maps.googleapis.com/maps/api/js?key=AIzaSyDtvhKx1NiKIByGwpD_WPWwWAzV6usWivg&callback=initMap';

script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBy-DYLx5n9rfnRwZp4LmkBHgZSinDm5GQ&callback=initMap'; // script.defer = true;

script.async = true;
var globalStyles = [{
  featureType: 'water',
  elementType: 'geometry',
  stylers: [{
    color: '#e9e9e9'
  }, {
    lightness: 17
  }]
}, {
  featureType: 'landscape',
  elementType: 'geometry',
  stylers: [{
    color: '#f5f5f5'
  }, {
    lightness: 20
  }]
}, {
  featureType: 'road.highway',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#ffffff'
  }, {
    lightness: 17
  }]
}, {
  featureType: 'road.highway',
  elementType: 'geometry.stroke',
  stylers: [{
    color: '#ffffff'
  }, {
    lightness: 29
  }, {
    weight: 0.2
  }]
}, {
  featureType: 'road.arterial',
  elementType: 'geometry',
  stylers: [{
    color: '#ffffff'
  }, {
    lightness: 18
  }]
}, {
  featureType: 'road.local',
  elementType: 'geometry',
  stylers: [{
    color: '#ffffff'
  }, {
    lightness: 16
  }]
}, {
  featureType: 'poi',
  elementType: 'geometry',
  stylers: [{
    color: '#f5f5f5'
  }, {
    lightness: 21
  }]
}, {
  featureType: 'poi.park',
  elementType: 'geometry',
  stylers: [{
    color: '#dedede'
  }, {
    lightness: 21
  }]
}, {
  elementType: 'labels.text.stroke',
  stylers: [{
    visibility: 'on'
  }, {
    color: '#ffffff'
  }, {
    lightness: 16
  }]
}, {
  elementType: 'labels.text.fill',
  stylers: [{
    saturation: 36
  }, {
    color: '#333333'
  }, {
    lightness: 40
  }]
}, {
  elementType: 'labels.icon',
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: 'transit',
  elementType: 'geometry',
  stylers: [{
    color: '#f2f2f2'
  }, {
    lightness: 19
  }]
}, {
  featureType: 'administrative',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#fefefe'
  }, {
    lightness: 20
  }]
}, {
  featureType: 'administrative',
  elementType: 'geometry.stroke',
  stylers: [{
    color: '#fefefe'
  }, {
    lightness: 17
  }, {
    weight: 1.2
  }]
}];
export var initContactMap = function initContactMap() {
  var initZoom = 15; // Set zoom for mobile

  if (window.matchMedia('(max-width: 992px)').matches) {
    initZoom = 14;
  } // Get main coord


  var mainCoord = document.querySelector('.contact__map-coords');
  var mainCoordY = mainCoord.getAttribute('data-coord-y') * 1;
  var mainCoordX = mainCoord.getAttribute('data-coord-x') * 1; // Get icon

  var icon = document.querySelector('.contact__map-point').getAttribute('data-point'); // Create the map.

  window.initMap = function () {
    var map = new google.maps.Map(document.getElementById('contact-map'), {
      zoom: initZoom,
      center: {
        lat: mainCoordY,
        lng: mainCoordX
      },
      mapTypeId: 'terrain',
      styles: globalStyles
    }); // Create marker

    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(mainCoordY, mainCoordX),
      map: map,
      title: 'Goczewski',
      icon: {
        url: icon,
        scaledSize: window.isMobile ? new google.maps.Size(20, 34) : new google.maps.Size(40, 68)
      }
    });
  };

  document.head.appendChild(script);
};