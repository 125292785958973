import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
export var initVideo = function initVideo() {
  var videos = document.querySelectorAll('[data-video-url]');

  if (videos.length) {
    _toConsumableArray(videos).forEach(function (video) {
      var url = video.dataset.videoUrl;
      var id = url.split("?v=")[1]; //sGbxmsDFVnE

      var embedlink = "https://www.youtube.com/embed/" + id;
      var ytube1 = document.getElementById(video.dataset.videoId);
      ytube1.src = embedlink; // video play button

      var playButton = video.querySelector('.play-video'); // overlay 

      var overlay = video.querySelector('.video-cover');
      playButton.addEventListener('click', function () {
        overlay.style.opacity = 0;
        overlay.style.visibility = 'hidden';
        playButton.style.opacity = 0;
        playButton.style.visibility = 'hidden';
        ytube1.src += "?autoplay=1";
      });
    });
  }
};