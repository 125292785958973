var mainNavLinks = document.querySelectorAll('.pricelist__nav-link');
var mainSections = document.querySelectorAll('.pricelist__box');
var lastId;
var cur = [];
export var initPricelist = function initPricelist() {
  console.log('dziala');
  window.addEventListener('scroll', function (event) {
    console.log('test');
    var fromTop = window.scrollY;
    mainNavLinks.forEach(function (link) {
      var section = document.querySelector(link.hash);

      if (section.offsetTop <= fromTop && section.offsetTop + section.offsetHeight > fromTop) {
        link.classList.add('pricelist__nav-link--active');
      } else {
        link.classList.remove('pricelist__nav-link--active');
      }
    });
  });
};