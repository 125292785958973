import HandyCollapse from "handy-collapse";
export var initHandyCollapse = function initHandyCollapse() {
  if (document.querySelector('.accordion')) {
    var myAccrodionCustom = new HandyCollapse({
      isAnimation: true,
      closeOthers: false,
      animationSpeed: 400,
      cssEasing: "ease"
    });
  }
};