import axios from "axios";

var loadMorePosts = function loadMorePosts(action, pageNumber, loader, button) {
  showLoader(loader, button);
  var params = new URLSearchParams();
  params.append("action", action);
  params.append("page", pageNumber);
  axios.post(ajaxurl, params).then(function (response) {
    var blog_posts_wrapper = document.getElementById("cases-load-more");
    blog_posts_wrapper.insertAdjacentHTML("beforeend", response.data);
    hideLoader(loader, button);
  }).catch(function (error) {
    console.log(error);
  });
  return false;
};

function showLoader(loader, button) {
  loader.classList.add('loading');
  button.disabled = true;
}

function hideLoader(loader, button) {
  loader.classList.remove('loading');
  button.disabled = false;
}

export var initPostsLoader = function initPostsLoader() {
  var loader = document.getElementById('case-loader');
  var button = document.querySelector('.load-more-button');
  var action = 'get_cases_posts'; // $count_posts = wp_count_posts( 'cases' )->publish;

  var TOTAL_COUNT_POSTS = parseInt(document.querySelector('[data-paged]').dataset.paged);
  var currentPageNumber = 1;
  button.addEventListener('click', function () {
    currentPageNumber += 1;

    if (currentPageNumber <= TOTAL_COUNT_POSTS) {
      loadMorePosts(action, currentPageNumber, loader, button);
      if (currentPageNumber === TOTAL_COUNT_POSTS) button.remove();
    }
  });
};