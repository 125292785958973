import Swiper, { Pagination, Autoplay } from 'swiper';
Swiper.use([Pagination, Autoplay]);
var id;

function move() {
  if (id) clearInterval(id);
  var autoplay = 5000 / 2;
  var elem = document.getElementById("progress");
  var width = 0;
  var autoplayTime = autoplay / 100;
  id = setInterval(frame, autoplayTime);

  function frame() {
    if (width >= 100) {
      clearInterval(id);
    } else {
      width = width + 0.5;
      elem.style.width = width + '%';
    }
  }
}

export var initHomepageSlider = function initHomepageSlider() {
  var homepageSlider = new Swiper('#homepage-slider', {
    // autoHeight: true, //enable auto height
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    on: {
      slideChange: move
    }
  });
};