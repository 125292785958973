import Cookies from 'js-cookie';
export var initCookies = function initCookies() {
  var cookieBox = document.getElementById('js-cookie-box');
  var cookieButton = document.getElementById('js-cookie-button');

  if (!Cookies.get('cookie-box')) {
    cookieBox.classList.remove('cookie-box--hide');

    cookieButton.onclick = function () {
      Cookies.set('cookie-box', true, {
        expires: 7
      });
      cookieBox.classList.add('cookie-box--hide');
    };
  }
};