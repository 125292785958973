export var initMenu = function initMenu() {
  // define all UI variable
  var mq = window.matchMedia('(max-width: 992px)');
  var navToggler = document.querySelector('.nav-toggler');
  var navMenu = document.querySelector('.site-navbar .nav-container');
  var navLinks = document.querySelectorAll('.site-navbar a'); // load all event listners

  allEventListners();

  function addTopbarToMenu(callback) {
    var afterElement = document.querySelector('.topbar-toggler');
    var topbar = document.querySelector('.topbar');
    var topbarNav = topbar.querySelector('.topbar__items');
    var topbarSm = topbar.querySelector('.navbar-sm'); // add on mobile

    afterElement.insertAdjacentElement('afterend', topbarNav);
    afterElement.insertAdjacentElement('afterend', topbarSm); // remove topbar

    topbar.remove();
    callback(topbarNav);
    callback(topbarSm);
  }

  var createButtonToOpenNav = function createButtonToOpenNav(topbarNav) {
    var toggler = document.querySelector('.topbar-toggler');
    toggler.addEventListener('click', function (e) {
      topbarNav.classList.toggle('open');
      toggler.classList.toggle('open');
    });
  }; // functions of all event listners


  function allEventListners() {
    // toggler icon click event
    navToggler.addEventListener('click', togglerClick); // nav links click event

    navLinks.forEach(function (elem) {
      return elem.addEventListener('click', navLinkClick);
    });
  } // togglerClick function


  function togglerClick() {
    navToggler.classList.toggle('toggler-open');
    navMenu.classList.toggle('open');
    document.body.classList.toggle('nav-open');
  } // navLinkClick function


  function navLinkClick() {
    if (navMenu.classList.contains('open')) {
      navToggler.click();
    }
  } // Run when media queries match


  if (mq.matches) {
    addTopbarToMenu(createButtonToOpenNav);
  }
};