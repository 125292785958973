// import $ from 'jquery';
import AOS from 'aos';
import SweetScroll from 'sweet-scroll';
import { initMenu } from './modules/menu';
import { initCookies } from './modules/cookies';
import { initHomepageSlider } from './modules/sliders';
import { initVideo } from './modules/video';
import { initHandyCollapse } from './modules/accordion';
import { initPricelist } from './modules/pricelist';
import { initContactMap } from './modules/map';
import { initLightbox } from './modules/lightbox';
import { initPostsLoader } from './modules/loadPosts';
var page = document.querySelector('body');

var fixHeader = function fixHeader() {
  var mediaQuery = window.matchMedia('(min-width: 992px)');
  var nav = null;

  if (mediaQuery.matches) {
    nav = document.querySelector('.fixed-menu');
  } else {
    nav = document.querySelector('.navbar-area');
  }

  var content = document.querySelector('#content');
  content.style.marginTop = "".concat(nav.clientHeight, "px");
};

document.addEventListener('DOMContentLoaded', function () {
  AOS.init({
    once: true,
    duration: 900
  });
  fixHeader();
  initMenu();
  initHomepageSlider();
  initVideo();
  initHandyCollapse();
  initLightbox();
  initCookies();
  setTimeout(function () {
    var preloader = document.querySelector('.preloader');
    preloader.style.opacity = '0';
  }, 250);

  if (page.classList.contains('page-template-page-pricelist')) {
    initPricelist();
  }

  if (page.classList.contains('page-template-page-cases')) {
    initPostsLoader();
  }

  if (page.classList.contains('page-template-page-contact')) {
    initContactMap();
  }

  var scroller = new SweetScroll({
    easing: 'easeInOutSine'
  });
});